@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,400;1,700&display=swap");

:root {
  --color-bg: #122939;
  --color-bg2: #0b1b26;
  --color-bg3: #15232d;
  --color-text: #9ee7ff;
  --color-text-menu: #9ee7ff;
  --color-text-menu-light: #5a90b7;
  --color-text-link: #019dd1;
  --color-text2: #aaaa8e;
  --color-text2-menu: #aaaa8e;
  --color-link: #e8e836;
  --color-shadow: black;

  /* === DARK THEME === */
  --dark-color-bg: #122939;
  --dark-color-bg2: #0b1b26;
  --dark-color-bg3: #15232d;
  --dark-color-text: #9ee7ff;
  --dark-color-text-menu: #9ee7ff;
  --dark-color-text-menu-light: #5a90b7;
  --dark-color-text-link: #019dd1;
  --dark-color-text2: #aaaa8e;
  --dark-color-text2-menu: #aaaa8e;
  --dark-color-link: #e8e836;
  --dark-color-shadow: rgb(142, 142, 142);

  /* === LIGHT THEME === */
  --light-color-bg: #ffffff;
  --light-color-bg2: #fefdf6;
  --light-color-bg3: #fdfbf3;
  --light-color-text: #015fac;
  --light-color-text-menu: #9ec8ff;
  --light-color-text-menu-light: #cac9c9;
  --light-color-text-link: #14307b;
  --light-color-text2: #000000;
  --light-color-text2-menu: #001055;
  --light-color-link: #e20606;
  --light-color-shadow: rgb(0, 0, 0);
}

body {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  /*font-family: Segoe UI Historic, Segoe UI, Helvetica, Arial, sans-serif;*/
  font-size: 16px;
  font-synthesis-weight: 500;
  background-color: var(--color-bg);
  color: var(--color-text);
}

.container {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
}

/* === HEADER === */

.header {
  padding: 25px 0;
  background-color: var(--color-bg);
  background: no-repeat center / cover url(../img/bg.jpg);
  background-blend-mode: multiply;
  position: relative;
  color: var(--color-text-menu);
}

.header__bgc {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 4, 36, 0.6);
  z-index: 0;
}

.header__inner {
  position: relative;
  padding-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 1.2;
  color: var(--color-text-menu);
}

.header__department {
  font-size: 36px;
  margin-bottom: 10px;
  font-weight: bold;
  text-shadow: 2px 2px 4px black;
}

.header__institute {
  font-size: 24px;
  font-style: italic;
  text-shadow: 2px 2px 4px black;
}

.header__nasu {
  font-size: 24px;
  font-style: italic;
  text-shadow: 2px 2px 4px black;
}

/* === NAV === */

.nav {
  position: sticky;
  top: 5px;
  margin: 0 0 10px 0;
  background-color: var(--color-bg2);
  box-shadow: 1px 2px 4px var(--color-shadow);
  border-top: 1px solid #808000;
}

.nav--fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  transform: translate3d(0, 0, 0);

  z-index: 1000;
}

/* .nav--active {
  background-color: var(--color-bg2);
} */

.nav__inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*  background-color: var(--color-bg2); */
  align-items: center;
  padding: 5px 0;

  position: relative;
}

.nav__menu {
  display: flex;
}

.nav__item {
  display: flex;
  height: 100%;
  text-decoration: none;
  text-transform: uppercase;
  color: var(--color-text2-menu);
  background-color: transparent;
  margin: 0px 10px;
  font-size: 14px;
  padding: 5px 0 2px 0;
  letter-spacing: 0.1em;
  line-height: 1.7;
  align-items: center;
  transition: all 0.2s ease-in;
}

.nav__item:first-child {
  margin-left: 0px;
}

.nav__lang .nav__item,
.nav__theme .nav__item {
  margin: 0 5px;
}

.nav__item:hover {
  /* color: var(--color-link); */
  color: var(--color-link);
}

.nav__item.active {
  color: var(--color-link);
}

.nav__theme {
  display: flex;
}

.nav__theme::after {
  content: "";
  display: flex;
  width: 1px;

  margin: 7px 5px;
  background-color: var(--color-link);
  align-items: center;
}

.nav__img-theme {
  display: flex;
  height: 16px;
  width: 16px;
  align-items: center;
  pointer-events: none;
}

.nav--very-light {
  color: var(--color-text-menu-light);
}

/* === NAV-TOGGLE === */
.nav-toggle {
  width: 30px;
  padding: 10px 0;
  position: absolute;
  top: 8px;
  right: 0px;

  background: none;
  border: 0;
  cursor: pointer;

  z-index: 1;

  font-size: 0;
  color: transparent;

  display: none;
}

.nav-toggle:focus {
  outline: 0;
}

.nav-toggle__item {
  width: 100%;
  height: 3px;
  background-color: var(--color-text2);
  display: block;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;

  transition: background 0.2s linear;
}

.nav-toggle.active .nav-toggle__item {
  background: none;
}

.nav-toggle__item::before,
.nav-toggle__item::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: var(--color-text2);

  position: absolute;
  left: 0;
  z-index: 1;

  transition: transform 0.2s linear;
}

.nav-toggle__item::before {
  top: -8px;
}

.nav-toggle__item::after {
  bottom: -8px;
}

.nav-toggle.active .nav-toggle__item::before {
  transform-origin: left top;
  transform: rotate(45deg) translateY(-3px);
}

.nav-toggle.active .nav-toggle__item::after {
  transform-origin: left bottom;
  transform: rotate(-45deg) translateY(3px);
}

/* === CONTENT === */

.content {
  font-size: 18px;
  color: var(--color-text2);
  font-weight: 500;
  letter-spacing: 0.1em;
}

.content__inner {
  padding: 0;
}

.content__inner.columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content-block {
  display: block;
  color: var(--color-text);
}

.content__inner a:hover {
  color: var(--color-link);
}

.content__descr,
.content__descr-ul {
  font-size: 16px;
  line-height: 1.2;
  margin-top: 15px;
  text-align: justify;
}

.content__descr-ul,
.content__descr-ul li {
  padding-left: 1.5em;
  line-height: 1.3;
  margin-bottom: 0.2em;
  list-style-type: circle;
  list-style-position: outside;
}

.content__descr.subhead {
  text-transform: uppercase;
}

.content__descr.under {
  text-decoration: underline;
}

.content__descr.vidstup {
  padding-left: 20px;
}

.content__descr-span {
  display: block;
  width: 100%;
  margin-bottom: 0.6em;
  line-height: 1.4;
  text-align: left;
}

.content__descr--head {
  color: var(--color-text-link);
  text-transform: uppercase;
  margin-left: -5px;
}

.content--dotted-bottom {
  border-bottom: var(--color-link) dotted 1px;
}

.projects_list li:last-child {
  border-bottom: none;
}

.span-name {
  font-size: 1.2em;
  font-weight: bold;
}

.span-name-a {
  font-size: 1.2em;
  font-weight: bold;
  width: fit-content;
  transition: all 0.2s ease-in;
}

.span-name-a:hover {
  color: var(--color-link);
  transition: all 0.2s ease-in;
}

.content__descr-img {
  display: flex;
  align-items: center;
}

.content__descr-img.column {
  flex-direction: row;
}

.content__descr.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content__descr-img.content__columns {
  width: 49%;
}

.content__descr-img-ref {
  align-items: center;
  justify-content: center;
  max-height: 36px;
  padding: 4px 3px;
  border-radius: 5px;
  border: 1px solid transparent;
}

.content__descr-img-ref.scopus {
  padding-top: 7px;
  padding-bottom: 1px;
}

.content__descr-img-ref:hover {
  background-color: var(--color-bg3);
  border-color: var(--color-link);
}

.content__img {
  margin: 15px 15px 0 0;
  max-height: 250px;
  object-fit: cover;
  align-items: center;
  border-radius: 10px;
}

.content__img-staff {
  max-width: 150px;
  border-radius: 15%;
}

.content__img-proj {
  max-width: 200px;
  max-height: fit-content;
  border-radius: 5%;
  padding-right: 20px;
}

.content__img-proj-big {
  max-height: 400px;
  border-radius: 10px;
  margin-right: 25px;
}

.content__descr.first {
  margin: 15px 0 10px 0;
}

.content__site {
  display: inline-block;
  padding-left: 0px;
  width: fit-content;
  color: var(--color-text);
}

.content__site-text {
  display: inline-block;
  padding-top: 15px;
  padding-bottom: 25px;
  margin-left: -5px;
}

.content__site:hover,
.content__site-text:hover {
  transition: all 0.2s ease-in;
}

/*
.content__title {
  display: inline-flex;
  font-size: 22px;
  font-style: italic;
  font-weight: bold;
  border: 1px solid rgba(141, 138, 138, 0.7);
  padding: 10px 15px;
  border-radius: 5px;
  margin: 20px 0 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}
*/

.content__title {
  display: block;
  font-size: 22px;
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 15px 10px 0px;
  margin: 20px 0 10px;
  color: var(--color-text-link);
}

.content__title::first-letter {
  font-size: 150%;
}

.content-one-column {
  display: inline-flex;
  width: 100%;
}

.content__projects {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 10px 0;
  justify-content: space-between;
  margin-bottom: 15px;
}

.content__projects-item {
  position: relative;
  width: 32%;
  min-height: 250px;
  border-radius: 8px;
  padding: 10px 15px;
  border: 1px solid var(--color-text);
  margin: 35px 0 10px 0;
}

.content__projects-item-img {
  display: flex;
  position: absolute;
  border-radius: 10px;
  top: -30px;
  left: 10px;
  height: 180px;
  width: 140px;
  align-items: center;
  justify-content: center;
  background-color: var(--color-bg);
  border: 1px solid var(--color-text);
  z-index: 10;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 1);
}

.content__projects-item img {
  object-fit: cover;
  max-height: 180px;
  max-width: 140px;
}

.content__projects-item-img:hover {
  background-color: var(--color-bg3);
}

.content__projects-item-title {
  margin-left: 140px;
  min-height: 155px;
  padding-top: 15px;
  color: var(--color-text-link);
  font-size: 18px;
  line-height: 1.4;
  text-transform: uppercase;
  text-align: center;
}

.content__projects-link {
  color: inherit;
}

.content__projects-link .content__projects-item-title:hover {
  color: var(--color-link);
}

.content__projects-item-descr {
  font-size: 14px;
  line-height: 1.2;
}

.content__list-publications-ol {
  margin-top: 1em;
  padding-left: 1.5em;
  list-style-type: decimal;
  list-style-position: outside;
}

.content__list-publications-ol li {
  line-height: 1.3;
  margin-bottom: 1em;
  border-bottom: 1px dotted;
}

.content__list-publications-ol li:last-child {
  border-bottom: none;
}

.content__list-title {
  font-size: 1.1em;
  display: block;
  font-weight: bold;
  margin-bottom: 0.2em;
}

.content__list-authors {
  display: block;
  font-style: italic;
  margin-bottom: 0.2em;
}

.content__list-issue,
.content__list-date,
.content__list-link {
  display: block;
  margin-bottom: 0.2em;
}

.content__list-link-a {
  color: var(--color-text);
  transition: all 0.2s ease-in;
}

.content__list-link-a:hover {
  color: var(--color-link);
  transition: all 0.2s ease-in;
}

.content__btn-year {
  font-size: 16px;
  padding: 10px 10px;
  background-color: transparent;
  color: var(--color-text);
}

.content__btn-year:first-child {
  padding-left: 0px;
}

.content__btn-year:hover {
  color: var(--color-link);
  transition: all 0.2s ease-in;
}

.content__text-search {
  font-size: 16px;
  color: var(--color-text);
  padding: 3px 5px;
  margin-right: 10px;
  width: 250px;
  border-radius: 4px;
  border: 2px solid var(--color-text);
  background-color: var(--color-bg);
}

.content__btn-search {
  font-size: 16px;
  color: var(--color-bg);
  padding: 3px 15px;
  border-radius: 4px;
  border: 2px solid var(--color-text);
  background-color: var(--color-text);
}

.content--justify {
  text-align: justify;
}

.content--top {
  align-items: start;
}

.content--center {
  display: flex;
  justify-content: center;
  width: 100%;
}

.content--subhead {
  text-transform: uppercase;
}

.content--subhead-color {
  color: var(--color-text-link);
}

.content--subhead-fs {
  font-size: 18px;
  font-weight: bold;
}

.content--bg-white {
  background-color: rgba(255, 255, 255, 0.163);
  border: rgba(141, 138, 138, 0.7) solid 1px;
}

.content__loading {
  display: inline-block;
  min-width: 100%;
  font-size: 32px;
  text-align: center;
  padding: 25px 0;
  color: var(--color-text-link);
}

.content__up_bottom {
  border-top: 1px solid #808000;
  border-bottom: 1px solid #808000;
}

.content__year_active {
  color: var(--color-link);
}

/* === FOOTER === */
.footer {
  margin-top: 25px;
  background-color: var(--color-bg2);
  /*box-shadow: 1px -2px 4px black;*/
  border-top: 1px solid #808000;
  border-bottom: 1px solid #808000;
  color: var(--color-text);
}

.footer__inner {
  display: flex;
  min-height: 50px;
  justify-content: space-around;
  font-size: 12px;
  align-items: center;
  letter-spacing: 0.1em;
  line-height: 1.2;
}

.footer__copyright {
  width: 75%;
  text-align: left;
}

.footer__developer {
  width: 15%;
  text-align: center;
}

/* === WBS === */

.content__wbs {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 40vmax;
  line-height: 2;
  font-size: 36px;
  color: var(--color-link);
  flex-wrap: wrap;
}

.wbs {
  text-align: center;
  margin-bottom: 20px;
}

.btn__test {
  display: block;
  color: var(--color-text);
  padding: 5px 25px;
  font-size: 16px;
  background-color: var(--color-bg3);
  border: var(--color-link) solid 1px;
  border-radius: 5px;
}
.btn__test:hover {
  background-color: var(--color-link);
  color: var(--color-bg3);
}

/*=== MEDIA === */

@media (max-width: 1100px) {
  .header__department {
    font-size: 32px;
  }

  .header__institute {
    font-size: 20px;
  }

  .header__nasu {
    font-size: 20px;
  }

  .nav__item {
    font-size: 12px;
    margin: 0px 7px;
  }

  .content__projects-item-title {
    font-size: 16px;
  }
}

@media (max-width: 1000px) {
  .header__department {
    font-size: 26px;
  }

  .header__institute {
    font-size: 18px;
  }

  .header__nasu {
    font-size: 18px;
  }

  .nav__item {
    font-size: 12px;
    margin: 0px 5px;
  }

  .content__title {
    font-size: 18px;
  }

  .content__descr,
  .content__descr-ul,
  .content__descr-ul li {
    font-size: 14px;
  }

  .content__descr-span {
    text-align: center;
  }

  .content__projects-item {
    width: 49%;
  }

  .content__descr-img.column {
    flex-direction: column;
  }

  .content__descr-img.content__columns {
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .header__department {
    font-size: 24px;
  }

  .header__institute {
    font-size: 16px;
  }

  .header__nasu {
    font-size: 16px;
  }

  .nav__menu-hidden {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--color-bg2);
    flex-direction: column;
    box-shadow: 1px 2px 4px var(--color-shadow);
  }

  .nav__menu-active {
    display: flex;
  }

  .nav__menu-hidden .nav__item {
    margin: 0;
    padding: 8px 20px;
  }

  .nav__inner {
    justify-content: end;
  }

  .nav__lang {
    margin-right: 45px;
  }

  .nav-toggle {
    display: block;
  }

  .content__projects-item {
    width: 100%;
  }

  .content__wbs {
    font-size: 24px;
  }

  .content__descr-img.content__columns {
    flex-direction: row;
    width: 100%;
  }

  .content__descr-span {
    text-align: left;
  }

  .content__descr-img-proj {
    flex-direction: column;
  }

  .content__descr-img-caption {
    flex-direction: column;
    font-style: italic;
  }

  .content__img-proj-big {
    width: 80%;
    max-height: fit-content;
    margin: 0px;
    padding: 0px;
  }
}

@media (max-width: 400px) {
  .header__department {
    font-size: 18px;
  }

  .header__institute {
    font-size: 12px;
  }

  .header__nasu {
    font-size: 12px;
  }

  .content__title {
    font-size: 16px;
  }

  .content__descr,
  .content__descr-ul,
  .content__descr-ul li {
    font-size: 12px;
    text-align: left;
  }

  .content__descr-span {
    text-align: center;
  }

  .content__img-proj-big {
    width: 100%;
    max-height: fit-content;
  }

  .content__descr-img.content__columns {
    flex-direction: column;
  }
}
